import React from 'react';

function App() {
  return (
    <div className="app">
      Periegesis starter
    </div>
  );
}

export default App;
